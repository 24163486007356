exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-commercial-solar-jsx": () => import("./../../../src/pages/commercial-solar.jsx" /* webpackChunkName: "component---src-pages-commercial-solar-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-electrical-contracting-jsx": () => import("./../../../src/pages/electrical-contracting.jsx" /* webpackChunkName: "component---src-pages-electrical-contracting-jsx" */),
  "component---src-pages-ev-chargers-jsx": () => import("./../../../src/pages/ev-chargers.jsx" /* webpackChunkName: "component---src-pages-ev-chargers-jsx" */),
  "component---src-pages-heat-pumps-jsx": () => import("./../../../src/pages/heat-pumps.jsx" /* webpackChunkName: "component---src-pages-heat-pumps-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-inverter-upgrades-jsx": () => import("./../../../src/pages/inverter-upgrades.jsx" /* webpackChunkName: "component---src-pages-inverter-upgrades-jsx" */),
  "component---src-pages-solar-pv-jsx": () => import("./../../../src/pages/solar-pv.jsx" /* webpackChunkName: "component---src-pages-solar-pv-jsx" */)
}

